import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Card2 from './Card2';
import reportWebVitals from './reportWebVitals';
import AnimatedCursor from "react-animated-cursor"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/*app bar zIndex: 1100 , keep it higher than this.*/ }
    <div style={{ position: 'relative', zIndex: 1200 }}> 
   <AnimatedCursor
  innerSize={8}
  outerSize={30}
  innerScale={1}
  outerScale={2}
  outerAlpha={0}
  hasBlendMode={true}
  innerStyle={{
    backgroundColor: '#DD6071'
    //backgroundColor: '#FFA500'  //Orange
  }}
  outerStyle={{
    border: '2px solid #3580BF'
  }}
/>
</div>
   <Card2 /> 
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
